import {
  Box,
  Flex,
  Heading,
  Stack,
  useColorModeValue as mode,
  Spinner,
} from '@chakra-ui/react'
import {
  IoHomeSharp,
  IoFileTrayStacked,
  IoAirplaneSharp,
  IoCashSharp,
  IoSearchSharp,
  IoTicketSharp,
  IoSettingsSharp,
  IoLogOutSharp,
} from 'react-icons/io5'
import { useAuth } from '@redwoodjs/auth'
import { MobileMenuButton } from './MobileMenuButton'
import { NavBreadcrumb } from './NavBreadcrumb'
import { NavSectionTitle } from './NavSectionTitle'
import { ScrollArea } from './ScrollArea'
import { SearchInput } from './SearchInput'
import { SidebarLink } from './SidebarLink'
import { useMobileMenuState } from './useMobileMenuState'
import { UserInfo } from './UserInfo'

const settings = require('../../../settings.json')

const domain = document.domain.toLowerCase()

const siteSettings = {
  name: settings.domains[0].name,
  color: settings.domains[0].color,
}

for (const setting of settings.domains) {
  if (setting.url === domain) {
    siteSettings.name = setting.name
    siteSettings.color = setting.color
  }
}

document.title = siteSettings.name

const DashboardPage = () => {
  const { logOut, currentUser } = useAuth()
  const { isOpen, toggle } = useMobileMenuState()

  const user = currentUser
  const email = user?.email || ''

  return (
    <Flex
      height="100vh"
      bg={mode(siteSettings.color, 'gray.800')}
      overflow="hidden"
      sx={{ '--sidebar-width': '16rem' }}
    >
      <Box
        as="nav"
        display="block"
        flex="1"
        width="var(--sidebar-width)"
        left="0"
        py="5"
        px="3"
        color="gray.200"
        position="fixed"
      >
        <Box fontSize="sm" lineHeight="tall">
          <Box
            as="a"
            href="#"
            p="3"
            display="block"
            transition="background 0.1s"
            rounded="xl"
            _hover={{ bg: 'whiteAlpha.200' }}
            whiteSpace="nowrap"
          >
            {!user && <Spinner />}
            {user && <UserInfo name={email} />}
          </Box>
          <ScrollArea pt="5" pb="6">
            <SidebarLink
              display={{ base: 'block', lg: 'none' }}
              mb="2"
              icon={<IoSearchSharp />}
            >
              Search
            </SidebarLink>
            <Stack pb="6">
              <SidebarLink icon={<IoHomeSharp />}>Command Centre</SidebarLink>
              <SidebarLink icon={<IoFileTrayStacked />}>Shows</SidebarLink>
              <SidebarLink icon={<IoCashSharp />}>Billing</SidebarLink>
            </Stack>
            <Stack pb="6">
              <NavSectionTitle>2022 Show</NavSectionTitle>
              <SidebarLink icon={<IoTicketSharp />}>Tickets</SidebarLink>
              <SidebarLink icon={<IoAirplaneSharp />}>Exhibitors</SidebarLink>
            </Stack>
            <Stack pb="6">
              <NavSectionTitle>Account</NavSectionTitle>
              <SidebarLink icon={<IoSettingsSharp />}>Settings</SidebarLink>
              <SidebarLink onClick={logOut} icon={<IoLogOutSharp />}>
                Sign Out
              </SidebarLink>
            </Stack>
          </ScrollArea>
        </Box>
      </Box>
      <Box
        flex="1"
        p={{ base: '0', md: '6' }}
        marginStart={{ md: 'var(--sidebar-width)' }}
        position="relative"
        left={isOpen ? 'var(--sidebar-width)' : '0'}
        transition="left 0.2s"
      >
        <Box
          maxW="2560px"
          bg={mode('white', 'gray.700')}
          height="100%"
          pb="6"
          rounded={{ md: 'lg' }}
        >
          <Flex direction="column" height="full">
            <Flex
              w="full"
              py="4"
              justify="space-between"
              align="center"
              px="10"
            >
              <Flex align="center" minH="8">
                <MobileMenuButton onClick={toggle} isOpen={isOpen} />
                <NavBreadcrumb />
              </Flex>
              <SearchInput />
            </Flex>
            <Flex direction="column" flex="1" overflow="auto" px="10" pt="8">
              <Heading size="md" fontWeight="extrabold" mb="6">
                {siteSettings.name}
              </Heading>
              <Box
                flex="1"
                borderWidth="3px"
                borderStyle="dashed"
                rounded="xl"
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

export default DashboardPage
