import { useAuth } from '@redwoodjs/auth'
import {
  Box,
  Heading,
  Button,
  Stack,
  Text,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react'

const HomePage = () => {
  const { logIn, isAuthenticated, client, loading } = useAuth()

  client.on('close', () => window.location.reload())

  return (
    <Box as="section" py="20">
      <Stack maxW="xl" mx="auto" spacing="8" textAlign="center">
        <Stack spacing="3">
          <Heading as="h1" letterSpacing="tight">
            Air Festival Command Centre
          </Heading>
          <Text
            fontWeight="medium"
            color={useColorModeValue('gray.600', 'gray.400')}
          >
            Login with credentials provided by the administrator.
          </Text>
        </Stack>

        {loading && <Spinner />}
        {!loading && !isAuthenticated && (
          <Button
            fontSize="sm"
            fontWeight="bold"
            size="lg"
            iconSpacing="3"
            colorScheme="blue"
            width="full"
            onClick={logIn}
          >
            Login
          </Button>
        )}
      </Stack>
    </Box>
  )
}

export default HomePage
