import { useAuth } from '@redwoodjs/auth'
import { Router, Route } from '@redwoodjs/router'

import DashboardPage from './pages/DashboardPage/DashboardPage'
import HomePage from './pages/HomePage/HomePage'

const Routes = () => {
  const { isAuthenticated, loading } = useAuth()

  const showHomePage = loading || !isAuthenticated

  return (
    <Router>
      <Route path="/" page={showHomePage ? HomePage : DashboardPage} name="home" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
